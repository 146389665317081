.hero,
.h1,
.h2,
.h3,
.h4
    +satoshi-medium

.hero
    font-size: 96px
    line-height: 1
    letter-spacing: -.02em
    +m
        font-size: 64px

.h1
    font-size: 48px
    line-height: 1.2
    +t
        font-size: 48px
        line-height: calc(56 / 48)
    +m
        font-size: 37px


.h2
    font-size: 42px
    line-height: calc(56 / 48)
    +t
        font-size: 40px
        line-height: calc(48 / 40)
    +m
        font-size: 32px

.h3
    font-size: 32px
    line-height: calc(48 / 40)
    +t
        font-size: 32px
        line-height: calc(40 / 32)

.h4
    font-size: 32px
    line-height: calc(40 / 32)

