@import ../../../styles/helpers

.head
    max-width: 580px
    margin: 0 0 80px
    text-align: left
    +d
        margin-bottom: 64px
    +m
        text-align: center
        margin: 0 auto 64px


.stage
    margin-bottom: 8px
    color: $neutrals4

.list
    display: flex
    +m
        text-align: center
        width: 320px
        margin: 0 auto
        flex-direction: column
.title
    .accent
        +strong
        +strong-theme-3
        +unused-strong
.item
    flex: 0 0 31%
    padding: 34px 83px
    +x
        padding: 34px 64px
    +d
        padding: 32px
    +m
        padding: 0 
    &:first-child
        padding-left: 0
    &:nth-child(2)
        flex: 0 0 38%
        +m
            border: none
        +dark
            border-color: $neutrals3
    &:last-child
        padding-right: 0
    &:not(:last-child)
        +m
            margin-bottom: 24px
            padding-bottom: 24px

.icon
    margin-bottom: 32px
    width: 85px
    height: 85px
    svg
        +dark
            fill: $neutrals8

.category
    margin-bottom: 12px
    +satoshi-bold
    
.content
    +caption-1
    color: $neutrals4