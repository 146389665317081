@import ../../../styles/helpers

.hero
    position: relative
    display: flex
    align-items: center
    min-height: 780px
    padding: 0px 0 130px
    +x
        min-height: 800px
    +d
        min-height: 624px
    +m
        min-height: auto
        padding: 60px 0 30px

.wrap
    position: relative
    z-index: 3
    max-width: 430px
    .accent
        color: #abb6ff
        +satoshi-bold
        +strong
        +strong-theme-1
    +t
        max-width: 340px
    +m
        max-width: 400px
        margin: 0 auto 80px
        text-align: center
    

.title
    margin-bottom: 20px

.text
    margin-bottom: 30px
    color: $neutrals4

.btns
    display: flex
    +m
        justify-content: center

.button
    &:not(:last-child)
        margin-right: 16px
    
.scroll
    position: absolute
    bottom: 96px
    +d
        bottom: 48px
    +m
        bottom: 64px

.gallery
    position: absolute
    top: 0%
    right: -70px
    width: 740px
    transform: translateY(2%)
    pointer-events: none
    +x
        right: -90px
        transform: translate(8%, 8%)
    +d
        right: -180px
        transform: translate(8%, 2%)
    +t
        right: -180px
        transform: translate(8%, -1%)
    
    +mt
        right: -300px
        transform: translate(8%, -3%)
    +m
        position: relative
        top: auto
        right: auto
        left: -16px
        margin: 0 auto
        transform: translate(0,0)
        pointer-events: all


.preview
    &:first-child
        position: relative
        z-index: 2
        border-radius: 10px
        box-shadow: inset 0 1px 1px 0 #ffffff1a, 0 50px 100px -20px #ceceff4d, 0 30px 60px -30px #d8d8d84d
        +m
            display: none
        +dark
            box-shadow: inset 0 1px 1px 0 #000000ab, 0 50px 100px -20px #3422704d, 0 30px 60px -30px #2c2c2ce0


    &:nth-child(2)
        top: 15%
        left: -7%
        z-index: 3
        width: 250px
        border-radius: 40px
        box-shadow: inset 0 1px 1px 0 #ffffff1a, 0 50px 100px -20px #ceceff4d, 0 30px 60px -30px #d8d8d84d
        +d
            width: 230px
            left: -5%
        +m
            display: none
        +dark
            box-shadow: inset 0 0px 0px 0 #000000ab, 0 50px 100px -20px #3422704d, 0 30px 60px -30px #2c2c2ce0

    &:nth-child(3)
        top: 69px
        right: 70px
        z-index: 3
        width: 240px
        border-radius: 10px
        box-shadow: 0 50px 100px -20px #6262ad40, 0 30px 60px -30px #586dad33
        +d
            display: none
        +dark
            box-shadow: 0 50px 100px -20px #2e1a7129, 0 30px 60px -30px #080d1c40
    &:not(:first-child)
        position: absolute
    img
        width: 100%

.shadow
    position: absolute
    bottom: -80px
    right: -80px
    z-index: -1
    display: none

.blur
    position: absolute
    top: -250px
    right: -380px
    z-index: 0
    +dark
        top: -500px
        right: -290px
        opacity: .35
    +d
        opacity: .3
    +m
        display: none
