// media queries
=w
  @media only screen and (max-width: "1419px")
    @content
=x
  @media only screen and (max-width: "1339px")
    @content
=d
  @media only screen and (max-width: "1159px")
    @content
=t
  @media only screen and (max-width: "1023px")
    @content

=mt
  @media only screen and (max-width: "992px")
    @content

=m
  @media only screen and (max-width: "767px")
    @content
=a
  @media only screen and (max-width: "639px")
    @content
=s
  @media only screen and (max-width: "474px")
    @content

// fonts
=poppins
  font-family: 'Poppins', sans-serif

=sf-pro-display
  font-family: 'SFProDisplay-Bold'

=satoshi-bold
  font-family: 'Satoshi Bold'

=satoshi-medium
  font-family: 'Satoshi Medium'

=satoshi-light
  font-family: 'Satoshi Light'

  // colors
$primary: #0D0D3F
$blue: #5766EC
$red: #FF592C
$white: #ffffff
$lightBlue: #E9ECFF
$purple: #9757D7
$purple-light: #CDB4DB
$pink: #EF466F
$green: #45B26B
$asphalt: #E4D7CF
$yellow: #FFD166
$border: #EFEFEF

$neutrals1: #272B3C
$neutrals2: #313547
$neutrals3: #353945
$neutrals4: #93A9C3
$neutrals5: #B1B5C3
$neutrals6: #E6E8EC
$neutrals7: #F7F8FB
$neutrals8: #FCFCFD

// typography
=body-1
  font-size: 24px
  line-height: calc(32 / 24)
  letter-spacing: -.01em

=body-2
  font-size: 24px
  line-height: calc(32 / 24)
  font-weight: 600

=caption-1
  font-size: 18px
  line-height: calc(24 / 14)

=caption-2
  font-size: 12px
  line-height: calc(20 / 12)

=hairline-1
  line-height: 1
  font-weight: 700
  text-transform: uppercase

=hairline-2
  font-size: 12px
  line-height: 1
  font-weight: 700
  text-transform: uppercase

=button-1
  +satoshi-medium
  font-size: 16px
  line-height: 1

=button-2
  +satoshi-medium
  font-size: 16px
  line-height: calc(16 / 14)

//triangle
=arr($width, $height, $bg, $direction)
  width: 0px
  height: 0px
  border-style: solid
  @if $direction == t
    border-width: 0 $width / 2 + px $height + px $width / 2 + px
    border-color: transparent transparent $bg transparent
  @if $direction == r
    border-width: $height / 2 + px 0 $height / 2 + px $width + px
    border-color: transparent transparent transparent $bg
  @if $direction == b
    border-width: $height + px $width / 2 + px 0 $width / 2 + px
    border-color: $bg transparent transparent transparent
  @if $direction == l
    border-width: $height / 2 + px $width + px $height / 2 + px 0
    border-color: transparent $bg transparent transparent
  @if $direction == tl
    border-width: $height + px $width + px 0 0
    border-color: $bg transparent transparent transparent
  @if $direction == tr
    border-width: 0 $width + px $height + px 0
    border-color: transparent $bg transparent transparent
  @if $direction == br
    border-width: 0 0 $height + px $width + px
    border-color: transparent transparent $bg transparent
  @if $direction == bl
    border-width: $width + px 0 0 $height + px
    border-color: transparent transparent transparent $bg
// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

// placeholder
=placeholder
  &::placeholder
    @content

// media query width
=r($width)
  @media only screen and (max-width: $width+ "px")
    @content

=rmin($width)
  @media only screen and (min-width: $width+ "px")
    @content

// theme
=dark
  @at-root :global(.dark-mode) &
    @content

=dark-body
  @at-root .dark-mode
    @content

=dark-common
  @at-root .dark-mode &
    @content

=full-height
  height: 100vh
  align-items: center
  display: flex
  +t
    min-height: 600px
    padding: 80px 0
  +m
    min-height: 600px
    height: auto

=transition
  transition: all 1.5s

=scale-animation
  +transition
  filter: blur(10px)
  transform: scale(.8)

=strong
  background: #fff
  -webkit-background-clip: text
  background-clip: text
  -webkit-text-fill-color: transparent
  -webkit-box-decoration-break: clone
  box-decoration-break: clone
  background-image: linear-gradient(129deg,#0D0D3F,#0D0D3F)

=strong-theme-1
  background-image: linear-gradient(129deg, #fbfcff, #6772ef)
  +dark
    background-image: linear-gradient(129deg, #7368ff, #ffad67)

=strong-theme-2
  background-image: linear-gradient(129deg, #a5c9ff, #c46cff)
  +dark
    background-image: linear-gradient(129deg, #ffd48b, #e867ef)

=strong-theme-3
  background-image: linear-gradient(129deg, #c3c9ff, #ffc4bf)
  +dark
    background-image: linear-gradient(129deg, #8bbeff, #fbb2ff)

=unused-strong
  background-image: linear-gradient(129deg, #0D0D3F, #0D0D3F)

=feature-grad-1
  background: linear-gradient(222.21deg, rgba(149, 181, 255, 0.20) 2.17%, rgba(255, 209, 146, 0.20) 53.2%, rgba(255, 183, 247, 0.20) 97.35%), linear-gradient(275.11deg, #ECEEFF 5.17%, #F4F5FE 81.43%), #FFFFFF
  +dark
    background: linear-gradient(297.06deg, #5e4eab 7.33%, #ae81df 55.12%, #ffb69e 96.48%)

=feature-grad-2
  background: linear-gradient(180.69deg, rgba(255, 149, 149, 0.2) -6.19%, rgba(255, 209, 146, 0.2) 49.1%, rgba(114, 162, 255, 0.2) 140.88%), #FFFFFF
  +dark
    background: linear-gradient(37deg, #73b9fd 7.33%, #9581df 55.12%, #f4a5fd 96.48%)

=feature-grad-3
  background: linear-gradient(222.21deg, rgba(149, 181, 255, 0.20) 2.17%, rgba(255, 209, 146, 0.20) 53.2%, rgba(255, 183, 247, 0.20) 97.35%), linear-gradient(275.11deg, #ECEEFF 5.17%, #F4F5FE 81.43%), #FFFFFF
  +dark
    background: linear-gradient(297.06deg, #4E58AB 7.33%, #818ADF 55.12%, #BFC1FF 96.48%)
