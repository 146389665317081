@import ../../../styles/helpers

.section
    text-align: center
    position: relative
    +full-height

.stage
    margin-bottom: 16px

.title
    max-width: 650px
    margin: 0 auto 32px
    .accent
        color: #abb6ff
        +strong
        +strong-theme-2
    +t
        max-width: 550px

.text
    max-width: 450px
    margin: 0 auto 48px
    color: $neutrals4

.dapps
    position: absolute
    z-index: -1
    height: 100%
    top: 0
    right: 0
    +transition
    +x
        right: -200px
    +m
        right: -270px
    ~ .dapps
        left: 0
        +x
            left: -200px
        +m
            left: -270px

.button
    &:hover
        ~ .dapps
            +scale-animation