@import ../../../styles/helpers

.section
    +m
        padding-top: 64px

.head
    max-width: 450px
    margin: 0 auto 100px
    text-align: center
    +m
        margin-bottom: 50px

.stage
    margin-bottom: 32px

.title
    margin-bottom: 20px

.info
    margin-bottom: 24px
    +body-1
    color: $neutrals4

.button
    +m
        width: 100%

.wrap
    +m
        margin: 0 -12px

.item
    &:not(:last-child)
        margin-bottom: 150px
        +t
            margin-bottom: 80px
        +m
            margin-bottom: 0
        .accent
            +strong
            +strong-theme-3
            +unused-strong
    &:nth-child(2)
        .row
            flex-direction: row-reverse
            +m
                flex-direction: column-reverse
                margin-bottom: 60px
        .wrapper
            +feature-grad-2
        .accent
            +strong
            +strong-theme-2
            +unused-strong
    &:nth-child(3)
        .wrapper
            +feature-grad-3
        .accent
            +strong
            +strong-theme-1
            +unused-strong

.row
    display: flex
    align-items: center
    margin: 0 -16px
        
    +m
        flex-direction: column-reverse
        margin-bottom: 60px

.col
    flex: 0 0 calc(50% - 32px)
    width: calc(50% - 32px)
    margin: 0 16px
    .wrapper
        border-radius: 20px
        min-height: 440px
        max-width: 540px
        overflow: hidden
        display: flex
        justify-content: center
        +feature-grad-1
        +d
            min-height: 390px
        +t
            min-height: 390px
        +mt
            min-height: 320px
        +m
            min-height: 320px
        +s
            min-height: 280px
            max-width: 340px
            margin: 0 auto
        img
            width: 390px
            height: 100%
            border-radius: 20px
            position: relative
            bottom: -60px
            box-shadow: inset 0 0px 0px 0 #ffffff1a, 0 50px 50px -20px #5656ae4d, 0 30px 50px -30px #d24cba4d
            +dark
                box-shadow: 0 70px 80px 10px #171741c2, 0 30px 50px -10px #2d2c44fa
            +d
                width: 350px
                bottom: -60px
            +t
                width: 350px
                bottom: -60px
            +mt
                width: 300px
                bottom: -50px
            +m
                width: 300px
                bottom: -50px
            +s
                width: 260px
                bottom: -40px
    +m
        flex: 0 0 100%
        width: 400px
        text-align: center
    &:first-child
        padding-top: 32px
        +d
            padding-top: 0
        +m
            margin-top: 30px

.details
    max-width: 340px
    margin: 0 auto
    +m
        max-width: 100%



.category
    margin-bottom: 16px
    +satoshi-bold


.content
    +caption-1
    color: $neutrals4