@import ../../../styles/helpers

.section
    text-align: center
    +full-height

.stage
    margin-bottom: 16px

.title
    max-width: 650px
    margin: 0 auto 32px
    .accent
        +strong
        +strong-theme-2
    +t
        max-width: 550px

.text
    max-width: 450px
    margin: 0 auto 48px
    color: $neutrals4

.gallery
    position: relative
    width: 200px
    pointer-events: none
    margin: 0 auto
    +d
        width: 180px
    +m
        position: relative
        top: auto
        right: auto
        width: 150px
        margin: 0 auto 42px
        transform: translateY(0)
    +s
        width: 120px

.preview
    border-radius: 23px
    img
        border-radius: 15px
        box-shadow: 0 20px 60px -20px rgba(255,129,168,0.15),0 30px 60px -30px rgba(255,174,174,0.2)
    &:first-child
        position: relative
        z-index: 100
    &:nth-child(2)
        top: 12%
        left: 80%
        z-index: 2
        img
            -webkit-transform: perspective( 600px ) rotateY( -25deg )
            transform: perspective( 600px ) rotateY( -25deg )
            box-shadow: 0 20px 60px -20px rgba(197,198,255,0.25),0 30px 60px -30px rgba(167,163,247,0.2)
    &:nth-child(3)
        top: 12%
        left: -60%
        z-index: 2
        img
            -webkit-transform: perspective( 600px ) rotateY( 25deg )
            transform: perspective( 600px ) rotateY( 25deg )
            box-shadow: 0 20px 60px -20px rgba(200,253,223,0.35),0 30px 60px -30px rgba(200,253,223,0.3)
    &:nth-child(4)
        top: 20%
        left: 140%
        z-index: 1
        img
            -webkit-transform: perspective( 600px ) rotateY( -35deg )
            transform: perspective( 600px ) rotateY( -35deg )
            box-shadow: 0 20px 60px -20px rgba(255,212,156,0.25),0 30px 20px -30px rgba(239,205,82,0.2)
    &:nth-child(5)
        top: 20%
        left: -110%
        z-index: 1
        img
            -webkit-transform: perspective( 600px ) rotateY( 35deg )
            transform: perspective( 600px ) rotateY( 35deg )
            box-shadow: 0 20px 60px -20px rgba(237,151,84,0.25),0 30px 20px -30px rgba(239,205,82,0.2)
    &:not(:first-child)
        position: absolute
        width: 80%
    &:nth-of-type(4), &:nth-of-type(5)
        width: 70%
    
    img
        width: 100%
        
