@import ../../styles/helpers

.container
    display: flex
    justify-content: space-between
    +m
        display: block

.body
    +m
        position: relative
        padding-top: 32px

.col
    padding: 80px 0px 64px
    display: flex
    align-items: center
    +x
        padding: 80px 0px 64px
    +t
        padding: 64px 0px 48px
    +m
        padding: 32px 0
        justify-content: center
    &:first-child
        display: flex
        align-items: center
        padding-left: 0
        +m
            display: block
    &:nth-child(2)
        padding-right: 0
    &:nth-child(3)
        +m
            border-left: none

.box
    display: flex
    flex-direction: column
    align-items: flex-start
    +m
        align-items: center

.logo
    display: inline-block
    margin-bottom: auto
    overflow: hidden
    .pic
        width: 140px
    +m
        width: auto
    img
        width: 166px

.item
    .category
        display: none
    &.active
        +m
            .category
                svg
                    transform: rotate(180deg)
            .menu
                display: flex

.menu
    display: flex
    flex-direction: row
    align-items: flex-start

.link
    +button-2
    color: $primary
    +dark
        color: $neutrals4
    transition: color .2s
    &:hover
        color: $blue
        +dark
            color: $neutrals8
    &:not(:last-child)
        margin-right: 24px

.category
    margin-bottom: 41px
    +hairline-2

.info
    +caption-1
    p
        &:not(:last-child)
            margin-bottom: 8px
    &:not(:last-child)
        margin-bottom: 24px

.foot
    padding: 10px 0
    border-top: 1px solid $neutrals6
    background: $blue
    +m
        padding: 10px 0 
        border: none
    +dark
        border-color: $neutrals3

.copyright
    margin: 0 auto
    +caption-2
    color: #fff
    text-align: center

.socials
    display: flex
    justify-content: flex-end

.social
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 34px
    height: 34px
    background: $neutrals7
    border: 1px solid $border
    border-radius: 10px
    +dark
        background: #2E2F44
        border: 1px solid #393F57
    svg
        fill: $primary
        transition: fill .2s
        +dark
            fill: #93A9C3
    &:hover svg
        fill: $neutrals2
        +dark
            fill: $neutrals4
    &:not(:last-child)
        margin-right: 24px