@import ../../styles/helpers

.theme
    // test
    // position: fixed !important
    // bottom: 20px
    // right: 20px
    // display: none !important
    // test
    display: inline-block
    position: relative
    user-select: none 
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    font-size: 0
    margin-left: 20px
    +m
        position: absolute
        // top: 60px
        right: 28px

.input
    position: absolute
    top: 0
    left: 0
    opacity: 0
    &:checked + .inner
        border-color: $neutrals2
        .box
            background: rgba($neutrals2, .3) 
            &:before
                width: 8px
                height: 24px
                transform: translate(50px,-50%)
                border-radius: 4px
    &:checked + .inner
        .icon
            svg
                fill: $neutrals8
                &:first-child
                    opacity: 0
                &:nth-child(2)
                    opacity: 1


.inner
    position: relative
    display: inline-block
    border: 4px solid $neutrals6
    border-radius: 24px 
    transition: all .2s

.box
    position: relative
    display: block
    width: 72px
    height: 32px
    box-shadow: inset 0 0 0 1px rgba($neutrals4, .4)
    background: $neutrals8
    border-radius: 16px 
    transition: all .2s
    &:before
        content: ""
        position: absolute
        top: 50%
        left: 4px
        transform: translateY(-50%)	
        width: 24px
        height: 24px
        border-radius: 50%
        background: $blue
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) 
        transition: all .2s 

.icon
    svg
        position: absolute
        top: 50%
        transform: translateY(-50%)
        fill: $neutrals5
        transition: all .2s
        &:first-child
            right: 5px
        &:nth-child(2)
            left: 5px
            opacity: 0