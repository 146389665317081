@import ../../../styles/helpers

.section
    position: relative
    display: flex
    align-items: center
    min-height: 816px
    padding: 100px 0
    +d
        min-height: 600px
        padding: 80px 0
    +m
        display: block
        min-height: auto

.wrap
    position: relative
    z-index: 3
    max-width: 450px
    +t
        max-width: 340px
    +m
        margin: 60px auto 0
        text-align: center
        max-width: 400px


.title
    margin-bottom: 32px

.text
    margin-bottom: 24px
    color: $neutrals4

.gallery
    position: absolute
    top: 50%
    right: calc(50% - 541px)
    width: 500px
    transform: translateY(-50%)
    pointer-events: none
    +d
        right: calc(50% - 440px)
        width: 390px
        transform: translateY(-44%)
    +t
        right: calc(50% - 380px)
    +m
        position: relative
        top: auto
        right: auto
        left: auto
        width: 500px
        margin: 0 auto
        transform: translateY(0)
    +a
        width: 90%
        margin: 0 auto
    +s
        width: 340px
        margin: 0 auto

.preview
    &:first-child
        position: relative
        z-index: 2
       
    &:nth-child(2)
        top: -27%
        left: 4%
        z-index: 1
        width: 33%
    &:nth-child(3)
        top: -18%
        right: 31%
        z-index: 1
        width: 33%
    &:not(:first-child)
        position: absolute
    img
        width: 100%