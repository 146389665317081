[class^="section"]
    padding: 100px 0
    +t
        padding: 80px 0
    +m
        padding: 60px 0
    &.with-image
        text-align: left
        +t
            text-align: center
        h2.h2
            margin: 0 0 32px 15px
            +t
                margin: 0 0 32px
        .container
            display: flex
            flex-direction: column
            align-items: flex-start
            +t
                align-items: center
        .device
            position: absolute
            right: -210px
            height: 100%
            top: 0
            display: block
            z-index: 0
            +d
                left: 530px
            +x
                left: 550px
            +t
                display: none
        .stage
            display: none
            +t
                display: block
        .market
            justify-content: flex-start

            +m
                width: 400px
            +s
                width: 320px
            +t
                justify-content: center

.section-bg
    background: $neutrals7 
    +dark-common
        background: $neutrals2



.section-pb
    padding-top: 0
    +d
        padding-top: 0
    +m
        padding-top: 0

.section-pb64
    padding: 0 0 64px
    +d
        padding: 0 0 64px

