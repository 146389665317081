@import ../../../styles/helpers

.section
    text-align: center
    padding: 0

.stage
    margin-bottom: 16px
    img
        width: 70px

.title
    max-width: 320px
    margin: 0 auto 32px
    +dark
        color: $primary
    +t
        max-width: 550px

.market
    display: flex
    justify-content: center
    margin: 0 auto 12px
    width: 480px
    flex-wrap: wrap
    z-index: 1
    +m
        width: 400px
    +s
        width: 320px
    li
        border-radius: 7px
        overflow: hidden
        margin-right: 20px
        margin-bottom: 10px
        width: 100px
        position: relative
        &:not(:last-child)
            margin-right: 20px
        
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10)
            &::after
                content: "soon"
                position: absolute
                display: block
                left: 0
                top: 10px
                right: 0
                bottom: 0
                background-color: rgb(211 221 255)
                color: $primary
                text-align: center
                width: 40px
                height: 15px
                line-height: 12px
                margin: auto
                box-sizing: border-box
                font-size: 14px
                border-radius: 10px
                +dark
                    background-color: rgb(255 255 255 / 32%)
            &::before
                content: ''
                position: absolute
                bottom: 0
                left: 0
                top: 0
                display: flex
                justify-content: center
                align-items: center
                width: 100%
                text-align: center
                padding: 5px 0
                box-sizing: border-box
                border-radius: 0 0 7px 7px
                cursor: default
                +dark
                    background-color: transparent
        &:nth-child(1), &:nth-child(2)
            &::after
                content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iNyIgZmlsbD0id2hpdGUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC41MDQgMS4zMTgzNkMxNS41NzczIDEuMzE4MzYgMTkuNjkxNiA1LjQzMjY4IDE5LjY5MTYgMTAuNTA2QzE5LjY5MTYgMTUuNTc5MiAxNS41NzczIDE5LjY5MzYgMTAuNTA0IDE5LjY5MzZDNS40MzA3MyAxOS42OTM2IDEuMzE2NDEgMTUuNTc5MiAxLjMxNjQxIDEwLjUwNkMxLjMxNjQxIDUuNDMyNjggNS40MzA3MyAxLjMxODM2IDEwLjUwNCAxLjMxODM2Wk04LjU5NjA5IDEzLjQ5MDJMNi4zNDY3NCAxMS4yMzg5QzUuOTYzNTMgMTAuODU1NSA1Ljk2MzQ1IDEwLjIzMDIgNi4zNDY3NCA5Ljg0Njg3QzYuNzMwMTEgOS40NjM1OCA3LjM1ODE3IDkuNDY1OTggNy43Mzg3NSA5Ljg0Njg3TDkuMzI0NTMgMTEuNDMzOUwxMy4yNjk0IDcuNDg5MDFDMTMuNjUyOCA3LjEwNTY0IDE0LjI3ODEgNy4xMDU2NCAxNC42NjE0IDcuNDg5MDFDMTUuMDQ0OCA3Ljg3MjMgMTUuMDQ0MiA4LjQ5ODE5IDE0LjY2MTQgOC44ODEwMkwxMC4wMTk0IDEzLjUyM0M5LjYzNjU5IDEzLjkwNTggOS4wMTA2OSAxMy45MDY0IDguNjI3NCAxMy41MjNDOC42MTY2MyAxMy41MTIzIDguNjA2MjQgMTMuNTAxMyA4LjU5NjA5IDEzLjQ5MDJaIiBmaWxsPSIjNEZBQTREIi8+Cjwvc3ZnPgo=)
                position: absolute
                display: block
                left: 25px
                top: 0
                right: 0
                bottom: 50px
                // background-color: rgb(211 221 255)
                width: 18px
                height: 18px
                margin: auto
                box-sizing: border-box
                font-size: 14px
                border-radius: 10px
        a
            display: grid
            justify-items: center
            &:hover
                background: rgba($neutrals7, .5)
                +dark
                    background: rgba($neutrals7, .1)
            p
                height: 30px
                +dark
                    color: $primary
        +m
            &:not(:last-child)
                margin-bottom: 10px
        // &:nth-child(5), &:nth-child(6), &:nth-child(7)
        //     img
        //         opacity: .45
        //     p
        //         display: grid
        //         position: relative
        //         &::before
        //             content: 'soon'
        //             position: absolute
        //             top: -13px
        //             left: 0
        //             right: 0px
        //             margin: 0 auto
        //             width: 40px
        //             font-size: 10px
        //             text-transform: uppercase
        //             font-weight: bold
        //             letter-spacing: 1px
        //             padding: 2px 4px
        //             border-radius: 40px
        //             background: $lightBlue
        //             color: $neutrals4

    
.text
    max-width: 450px
    margin: 0 auto 48px
    color: $neutrals4

.cta
    background: #dbdbdb
    background: linear-gradient(222.21deg, rgba(149, 181, 255, 0.20) 2.17%, rgba(255, 209, 146, 0.20) 53.2%, rgba(255, 183, 247, 0.20) 97.35%), linear-gradient(275.11deg, #ECEEFF 5.17%, #F4F5FE 81.43%), #FFFFFF
    border-radius: 20px
    padding: 60px
    display: flex
    flex-direction: column
    align-items: center
    position: relative
    overflow: hidden
    background-size: 280% 280%
    animation: gradient-animation 5s ease infinite
    .title ~ .market
        margin: 0 0 12px
    .device
        display: none
    +dark
        background: linear-gradient(235.57deg, #a0c4ff 11.92%, #9989ff 57.88%, #fdb5ff 97.64%), #272B3C
        animation: gradient-animation 5s ease infinite
        background-size: 490% 410%
@keyframes gradient-animation 
    0% 
        background-position: 0% 50% 
    50% 
        background-position: 100% 50% 
    100% 
        background-position: 0% 50%