@import ../../../styles/helpers

.section
    position: relative
    +full-height

.container
    +m
        padding: 0 32px

.gallery
    position: absolute
    top: 50%
    left: -40px
    width: 624px
    transform: translateY(-50%)
    pointer-events: none
    +x
        left: -40px
        width: 580px
    +d
        left: -190px
    +m
        position: relative
        top: auto
        left: 50%
        width: 500px
        transform: translate(-50%,0)

.preview
    &:first-child
        position: relative
        z-index: 2
        +a
            width: 90%
            margin: 0 auto
        +s
            width: 340px
            margin: 0 auto
    &:nth-child(2)
        top: 42px
        right: 0
        z-index: 3
        width: 45%
        border-radius: 12px
        box-shadow: inset 0 1px 1px 0 #ffffff1a, 0 50px 100px -20px #ceceff4d, 0 30px 60px -30px #d8d8d84d
        +dark    
            box-shadow: inset 0 1px 1px 0 #0000001a, 0 50px 100px -20px #2323394d, 0 30px 60px -30px #110f0f4d
        +m
            top: 34px
        +a
            top: 31px
            right: 5%
        +s
            top: 22px
            right: 16%
            width: 35%
    &:not(:first-child)
        position: absolute
    img
        width: 100%

.wrap
    position: relative
    z-index: 3
    max-width: 450px
    margin-left: auto
    +t
        max-width: 340px
    +m
        margin: 60px auto 0
        text-align: center
        max-width: 400px

    

.title
    margin-bottom: 24px
    .accent
        +strong
        +strong-theme-3
        +unused-strong

.info
    margin-bottom: 24px
    color: $neutrals4

.list
    margin-bottom: 24px

.item
    display: flex
    padding: 24px
    border-radius: 12px
    cursor: pointer
    transition: box-shadow .2s
    &:hover
        box-shadow: inset 0 0 0 2px $neutrals6
        +dark
            box-shadow: inset 0 0 0 2px $neutrals3
    &:not(:last-child)
        margin-bottom: 4px

.icon
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 40px
    height: 40px
    margin-right: 24px
    border-radius: 50%

.details
    flex-grow: 1

.subtitle
    margin-bottom: 4px
    font-weight: 600

.content
    max-width: 260px
    +caption-1
    color: $neutrals4

.note
    margin-bottom: 24px
    margin-right: -10px
    +caption-1
    color: $neutrals4
    +m
        margin: 0 0 24px
        text-align: center
    strong
        font-weight: 600

.green
    color: $green

.black
    color: $neutrals2
    +dark
        color: $neutrals8

.btns
    text-align: right

.button
    +m
        width: 100%